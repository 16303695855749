var Permissions = /* @__PURE__ */ ((Permissions2) => {
  Permissions2["Leo"] = "Leo";
  Permissions2["ViewIncidents"] = "ViewIncidents";
  Permissions2["ManageIncidents"] = "ManageIncidents";
  Permissions2["ViewImpoundLot"] = "ViewImpoundLot";
  Permissions2["ManageImpoundLot"] = "ManageImpoundLot";
  Permissions2["ViewJail"] = "ViewJail";
  Permissions2["ManageJail"] = "ManageJail";
  Permissions2["ViewCallHistory"] = "ViewCallHistory";
  Permissions2["ManageCallHistory"] = "ManageCallHistory";
  Permissions2["LiveMap"] = "LiveMap";
  Permissions2["ViewCitizenLogs"] = "ViewCitizenLogs";
  Permissions2["ViewLicenseExams"] = "ViewLicenseExams";
  Permissions2["ManageLicenseExams"] = "ManageLicenseExams";
  Permissions2["SetUserDefinedCallsignOnOfficer"] = "SetUserDefinedCallsignOnOfficer";
  Permissions2["ViewCustomFields"] = "ViewCustomFields";
  Permissions2["ManageCustomFields"] = "ManageCustomFields";
  Permissions2["ManageDMV"] = "ManageDMV";
  Permissions2["ManageBureauOfFirearms"] = "ManageBureauOfFirearms";
  Permissions2["DeleteCitizenRecords"] = "DeleteCitizenRecords";
  Permissions2["ManageCustomRoles"] = "ManageCustomRoles";
  Permissions2["ViewCustomRoles"] = "ViewCustomRoles";
  Permissions2["ManageWarrants"] = "ManageWarrants";
  Permissions2["ManageAwardsAndQualifications"] = "ManageAwardsAndQualifications";
  Permissions2["PurgeLeoIncidents"] = "PurgeLeoIncidents";
  Permissions2["LeoManageCustomFields"] = "LeoManageCustomFields";
  Permissions2["LeoManageCitizenProfile"] = "LeoManageCitizenProfile";
  Permissions2["Dispatch"] = "Dispatch";
  Permissions2["ManageSmartSigns"] = "ManageSmartSigns";
  Permissions2["ManageSmartMotorwaySigns"] = "ManageSmartMotorwaySigns";
  Permissions2["EmsFd"] = "EmsFd";
  Permissions2["ViewEmsFdIncidents"] = "ViewEmsFdIncidents";
  Permissions2["ManageEmsFdIncidents"] = "ManageEmsFdIncidents";
  Permissions2["DeclareCitizenDead"] = "DeclareCitizenDead";
  Permissions2["PurgeEmsFdIncidents"] = "PurgeEmsFdIncidents";
  Permissions2["ViewDeadCitizens"] = "ViewDeadCitizens";
  Permissions2["ManageDeadCitizens"] = "ManageDeadCitizens";
  Permissions2["SetUserDefinedCallsignOnEmsFd"] = "SetUserDefinedCallsignOnEmsFd";
  Permissions2["ViewTaxiCalls"] = "ViewTaxiCalls";
  Permissions2["ManageTaxiCalls"] = "ManageTaxiCalls";
  Permissions2["ViewTowCalls"] = "ViewTowCalls";
  Permissions2["ManageTowCalls"] = "ManageTowCalls";
  Permissions2["ViewTowLogs"] = "ViewTowLogs";
  Permissions2["CreateBusinesses"] = "CreateBusinesses";
  Permissions2["ManageCADSettings"] = "ManageCADSettings";
  Permissions2["ViewUsers"] = "ViewUsers";
  Permissions2["ManageUsers"] = "ManageUsers";
  Permissions2["BanUsers"] = "BanUsers";
  Permissions2["DeleteUsers"] = "DeleteUsers";
  Permissions2["ViewCitizens"] = "ViewCitizens";
  Permissions2["ManageCitizens"] = "ManageCitizens";
  Permissions2["DeleteCitizens"] = "DeleteCitizens";
  Permissions2["ViewUnits"] = "ViewUnits";
  Permissions2["ManageUnits"] = "ManageUnits";
  Permissions2["DeleteUnits"] = "DeleteUnits";
  Permissions2["ManageUnitCallsigns"] = "ManageUnitCallsigns";
  Permissions2["ManageUnitRank"] = "ManageUnitRank";
  Permissions2["ViewBusinesses"] = "ViewBusinesses";
  Permissions2["ManageBusinesses"] = "ManageBusinesses";
  Permissions2["DeleteBusinesses"] = "DeleteBusinesses";
  Permissions2["ViewExpungementRequests"] = "ViewExpungementRequests";
  Permissions2["ManageExpungementRequests"] = "ManageExpungementRequests";
  Permissions2["ViewNameChangeRequests"] = "ViewNameChangeRequests";
  Permissions2["ManageNameChangeRequests"] = "ManageNameChangeRequests";
  Permissions2["ManagePendingWarrants"] = "ManagePendingWarrants";
  Permissions2["ManageCourthousePosts"] = "ManageCourthousePosts";
  Permissions2["ImportCitizens"] = "ImportCitizens";
  Permissions2["ImportRegisteredVehicles"] = "ImportRegisteredVehicles";
  Permissions2["ImportRegisteredWeapons"] = "ImportRegisteredWeapons";
  Permissions2["DeleteRegisteredVehicles"] = "DeleteRegisteredVehicles";
  Permissions2["DeleteRegisteredWeapons"] = "DeleteRegisteredWeapons";
  Permissions2["ManageValueLicense"] = "ManageValueLicense";
  Permissions2["ManageValueGender"] = "ManageValueGender";
  Permissions2["ManageValueEthnicity"] = "ManageValueEthnicity";
  Permissions2["ManageValueVehicle"] = "ManageValueVehicle";
  Permissions2["ManageValueWeapon"] = "ManageValueWeapon";
  Permissions2["ManageValueBloodGroup"] = "ManageValueBloodGroup";
  Permissions2["ManageValueBusinessRole"] = "ManageValueBusinessRole";
  Permissions2["ManageValueCodes10"] = "ManageValueCodes10";
  Permissions2["ManageValuePenalCode"] = "ManageValuePenalCode";
  Permissions2["ManageValueDepartment"] = "ManageValueDepartment";
  Permissions2["ManageValueOfficerRank"] = "ManageValueOfficerRank";
  Permissions2["ManageValueDivision"] = "ManageValueDivision";
  Permissions2["ManageValueDLCategory"] = "ManageValueDLCategory";
  Permissions2["ManageValueImpoundLot"] = "ManageValueImpoundLot";
  Permissions2["ManageValueVehicleFlag"] = "ManageValueVehicleFlag";
  Permissions2["ManageValueCitizenFlag"] = "ManageValueCitizenFlag";
  Permissions2["ManageValueQualification"] = "ManageValueQualification";
  Permissions2["ManageValueCallType"] = "ManageValueCallType";
  Permissions2["ManageValueAddress"] = "ManageValueAddress";
  Permissions2["ManageValueEmergencyVehicle"] = "ManageValueEmergencyVehicle";
  Permissions2["ManageValueAddressFlag"] = "ManageValueAddressFlag";
  Permissions2["ManageValueVehicleTrimLevel"] = "ManageValueVehicleTrimLevel";
  Permissions2["ManageValueWeaponFlag"] = "ManageValueWeaponFlag";
  Permissions2["ViewDLExams"] = "ViewDLExams";
  Permissions2["ManageDLExams"] = "ManageDLExams";
  Permissions2["ViewWeaponExams"] = "ViewWeaponExams";
  Permissions2["ManageWeaponExams"] = "ManageWeaponExams";
  return Permissions2;
})(Permissions || {});
const allPermissions = Object.keys(Permissions);

export { Permissions, allPermissions };
